/**
 * OnboardingAlert
 */
class OnboardingAlert {
    /**
     * Build OnboardingAlert Instance
     * @param alert     {HTMLElement}
     */
    constructor(alert = null) {
        if (!alert) {
            console.warn('Failed to initialize OnboardingAlert');
            return;
        }

        // Globals
        this.alert = alert;
        this.dismissButton = this.alert.querySelector('[data-bs-dismiss="alert"]');

        this.attachEventListeners();
    }

    /**
     * Attach Event Listeners
     */
    attachEventListeners() {
        if (this.dismissButton) {
            // Removing this prevents Bootstrap's alert from dismissing
            this.dismissButton.removeAttribute('data-bs-dismiss');

            // Custom click interaction
            this.dismissButton.addEventListener('click', () => {
                if (!this.alert.dataset.onboardingKey || !this.alert.dataset.memberId) {
                    return;
                }

                this.handleOnboardingEventCompletion();
            });
        }
    }

    /**
     * Complete Onboarding, send ajax request and close alert
     */
    handleOnboardingEventCompletion() {
        let clickedOnAlert = this.alert;
        this.onboardingKey = this.alert.dataset.onboardingKey;
        this.memberID = this.alert.dataset.memberId;

        fetch(`/onboarding/ajax_complete/${this.onboardingKey}/${this.memberID}`, {
            method: 'POST',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then(response => response.json())
          .then(() => {
              const alertElement = document.querySelector(clickedOnAlert);
              if (alertElement) {
                  const alertInstance = bootstrap.Alert.getOrCreateInstance(alertElement);
                  alertInstance?.close();
              }
          })
          .catch(error => console.error('Error:', error));
    }
}

module.exports = OnboardingAlert;
