require('../modules/convertToBoolean');

/**
 * Define types of available OnboardingModal elements
 * @type {string[]}
 */
const availableOnboardingModalTypes = ['static', 'slider'];

/**
 * Define Desktop Screen Breakpoint
 * @type {number}
 */
const desktopBreakpoint = 992;

/**
 * Define current state, whether any onboarding action is being processed
 * Should only be able to fire 1 request at a time (regardless of how many modals are available)
 * @type {boolean}
 */
let processingRequest = false;

/**
 * Define current state, whether either the 'completed' or 'ignored' requests have completed
 * Should only be able to fire 1 request per modal
 * @type {boolean}
 */
let finishedRequest = false;

/**
 * OnboardingModal
 *
 * Autoload in the onboarding modal functionality, for both types of modals:
 * - Slider Modal
 * - Static Modal
 */
class OnboardingModal {

    /**
     * Define OnboardingSliderModal Config
     * @return {{
     *   sliderLimit: number,
     *   includePaginationDots: boolean
     * }}
     * @constant
     */
    static get ONBOARDING_SLIDER_MODAL_CONFIG() {
        return {
            sliderLimit: 3,
            includePaginationDots: true
        };
    }

    /**
     * Check toggle value for all onboarding modals processing...
     * @constant
     */
    static get PROCESSING_REQUEST() {
        return processingRequest;
    }

    /**
     * Toggle Value for PROCESSING_REQUEST constant
     * @param toggled
     * @constructor
     */
    static set PROCESSING_REQUEST(toggled) {
        processingRequest = toggled;
    }

    /**
     * Check toggle value for whether the current onboarding modal is finished...
     * @constant
     */
    get FINISHED_REQUEST() {
        return finishedRequest;
    }

    /**
     * Toggle Value for FINISHED_REQUEST constant
     * @param toggled
     * @constructor
     */
    set FINISHED_REQUEST(toggled) {
        finishedRequest = toggled;
    }

    /**
     * Build OnboardingModal Instance
     * @param modal                     {HTMLElement}
     * @param sliderLimit               {integer}
     * @param includePaginationDots     {boolean}
     */
    constructor(modal = null, {
        sliderLimit = OnboardingModal.ONBOARDING_SLIDER_MODAL_CONFIG.sliderLimit,
        includePaginationDots = OnboardingModal.ONBOARDING_SLIDER_MODAL_CONFIG.includePaginationDots
    } = {}) {
        // Get Type
        const modalDialogBox = modal.querySelector('.modal-dialog');
        const modalDialogBoxClasses = modalDialogBox.className.split(' ');
        const modalDialogBoxTypesByClass = {
            static: 'onboarding-modal-static-dialog',
            slider: 'onboarding-modal-slider-dialog'
        };

        const [ type ] = Object.keys(modalDialogBoxTypesByClass)
            .filter(key => {
                const { [key] : className } = modalDialogBoxTypesByClass;
                return modalDialogBoxClasses.includes(className)
            });

        if (!modal || !availableOnboardingModalTypes.includes(type)) {
            console.warn('Failed to initialize OnboardingModal');
            return;
        }

        // Build Config Object by Type
        const { [type]: config } = {
            static: {},
            slider: {
                sliderLimit,
                includePaginationDots
            }
        };
        
        const viewport = modal.getAttribute('data-viewport');

        // Globals
        this.modal = modal;
        this.modalInstance = bootstrap.Modal.getOrCreateInstance(modal);
        this.modalDialogBox = modalDialogBox;
        this.type = type;
        this.config = config;
        this.viewport = viewport;

        // Buttons that handle specific modal actions, like:
        // - next
        // - complete (and handle complete event through javascript handling)
        this.modalActionBtns = Array.prototype.slice.call(
            this.modal.querySelectorAll('button[data-onboarding-action]')
        );

        // Buttons that fire specific onboarding events via an AJAX request. Events are either:
        // - complete
        // - ignore
        const onboardingActionBtns = Array.prototype.slice.call(
            this.modal.querySelectorAll('[data-onboarding-action-url]')
        );

        // COMPLETE onboarding
        // - complete action, and fire the onboarding/completed event ... and then handle redirect afterwards
        this.completeWithRedirectOnboardingBtns = onboardingActionBtns.filter(btn => (
            btn.getAttribute('data-onboarding-redirect-url')
        ));

        // IGNORE onboarding
        // - dismiss modal, and fire the onboarding/ignored event
        const [ ignoreOnboardingBtn = null ] = onboardingActionBtns.filter(btn => (
            btn.getAttribute('data-bs-dismiss')
            && btn.getAttribute('data-bs-dismiss') === 'modal'
        ));
        this.ignoreOnboardingURL = ignoreOnboardingBtn
            ? ignoreOnboardingBtn.getAttribute('data-onboarding-action-url')
            : null;

        this.initialize();
    }

    /**
     * Initialize OnboardingModal
     * @private
     */
    initialize() {
        this.detectViewport();
        
        if (this.type === 'static') {
            this.attachGlobalsForOnboardingStaticModal();
        }

        if (this.type === 'slider') {
            this.modalDialogBox.classList.add('onboarding-modal-slider-initialized');
            this.attachGlobalsForOnboardingSliderModal();
        }

        this.attachEventListeners();
    }

    /**
     * Initialize OnboardingStaticModal
     * @private
     */
    attachGlobalsForOnboardingStaticModal() {
        // Nothing here yet
    }

    /**
     * Initialize OnboardingSliderModal
     * @private
     */
    attachGlobalsForOnboardingSliderModal() {
        this.currentStepNumber = 1;

        this.paginationDots = this.config.includePaginationDots
            ? Array.prototype.slice.call(this.modal.querySelectorAll('.onboarding-modal-pagination-dots li')) // NodeList to Array
            : [];
    }

    /**
     * Attach Event Listeners
     */
    attachEventListeners() {
        // Attach Event Listeners on (Onboarding IGNORE) - When the Bootstrap modal is dismissed
        this.modal.addEventListener('hidden.bs.modal', event => {
            this.handleOnboardingEventIgnored({
                requestURL: this.ignoreOnboardingURL
            });
        })

        // Attach Event Listeners on (Onboarding COMPLETE) Action Buttons - and immediately fire off an ajax request
        this.completeWithRedirectOnboardingBtns.forEach(btn => {
            const redirectURL = btn.getAttribute('data-onboarding-redirect-url') || null;
            const completeOnboardingURL = btn.getAttribute('data-onboarding-action-url') || null;
            if (completeOnboardingURL) {
                btn.addEventListener('click', event => {
                    event.preventDefault();
                    this.handleOnboardingEventCompletion(btn, {
                        requestURL: completeOnboardingURL,
                        redirectURL
                    });
                });
            }
        });

        // Attach Event Listeners on (Modal) Action Buttons, events like:
        // - next (slide)
        // - complete (and send ajax request)
        this.modalActionBtns.forEach(btn => {
            btn.addEventListener('click', () => {
                const onboardingAction = btn.getAttribute('data-onboarding-action');

                // Go to next Onboarding slide
                if (this.type === 'slider' && onboardingAction === 'next') {
                    this.nextStep();
                }

                // Complete Onboarding
                if (onboardingAction === 'complete') {
                    const url = btn.getAttribute('data-onboarding-action-url') || null;
                    this.handleOnboardingEventCompletion(btn, {
                        requestURL: url
                    });
                }
            });
        });

        // Attach Event Listeners on Pagination Dots (slider modals only)
        if (this.type === 'slider') {
            this.paginationDots.forEach(paginationDot => {
                paginationDot.addEventListener('click', () => {
                    const stepNumber = paginationDot.getAttribute('data-slider-step') || null;
                    if (stepNumber) {
                        this.goToStep(parseInt(stepNumber));
                    }
                })
            });
        }
    }

    /**
     * Ignore Onboarding, and send ajax request
     * @param requestURL    {string}
     */
    handleOnboardingEventIgnored({ requestURL } = {}) {
        if (!OnboardingModal.PROCESSING_REQUEST && !this.FINISHED_REQUEST) {
            // All remaining Onboarding Modals can still be processed
            OnboardingModal.PROCESSING_REQUEST = true;

            // This current Onboarding Modal is finished
            this.FINISHED_REQUEST = true;
            
            if(!requestURL){ return; }

            fetch(requestURL, {
                method: 'POST',
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            }).finally(() => {
                OnboardingModal.PROCESSING_REQUEST = false;
            });
        }
    }

    /**
     * Complete Onboarding, and send ajax request (No redirect to happen here)
     * @param clickedBtn        {HTMLElement}
     * @param requestURL        {string}
     * @param redirectURL       {string|null}
     */
    handleOnboardingEventCompletion(clickedBtn, {
        requestURL,
        redirectURL = null
    } = {}){
        if (!OnboardingModal.PROCESSING_REQUEST && !this.FINISHED_REQUEST) {
            // All remaining Onboarding Modals can still be processed
            OnboardingModal.PROCESSING_REQUEST = true;

            // This current Onboarding Modal is finished
            this.FINISHED_REQUEST = true;

            if (!redirectURL) {
                fetch(requestURL, {
                    method: 'POST',
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest'
                    }
                }).then(() => {
                    if (this.modal.classList.contains('modal')) {
                        this.modalInstance.hide();
                    }
                    OnboardingModal.PROCESSING_REQUEST = false;
                }).catch(error => console.error('Error:', error));
            }
            else {
                buttonSend(clickedBtn);
                fetch(requestURL, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest'
                    },
                    body: JSON.stringify({}) // Add any required body content here if needed
                }).then(response => response.json())
                  .then(response => {
                      if (response.status === 'success') {
                          window.location = redirectURL;
                      }
                  })
                  .finally(() => {
                      OnboardingModal.PROCESSING_REQUEST = false;
                  });
                buttonReturn(clickedBtn);
            }
        }
    }

    /**
     * (OnboardingSliderModal) Next Onboarding Step
     */
    nextStep() {
        // Increment current step number
        if (this.currentStepNumber < this.config.sliderLimit) {
            this.currentStepNumber++;
        }

        // Go to the updated step
        this.goToStep(this.currentStepNumber);
    }

    /**
     * (OnboardingSliderModal) Go to a specific Step Number
     * @param stepNumber
     */
    goToStep(stepNumber) {
        if (stepNumber <= this.config.sliderLimit) {
            this.currentStepNumber = stepNumber;
        }

        // Adjust step number in data attribute, which will handle the sliding effect through CSS
        this.modalDialogBox.setAttribute('data-slider-step', this.currentStepNumber);

        // Adjust current pagination dot
        if (this.config.includePaginationDots) {
            const previousActiveDot = this.modalDialogBox.querySelector(`.onboarding-modal-pagination-dots li.active`);
            previousActiveDot.classList.remove('active');

            const nextActiveDot = this.modalDialogBox.querySelector(`.onboarding-modal-pagination-dots li[data-slider-step="${this.currentStepNumber}"]`);
            nextActiveDot.classList.add('active');
        }
    }

    /**
     * Detect if Viewport Attribute was Assigned to Modal
     * and handle display for each screen size
     * NOTE: Requires displayOnLoad = false set on the Onboarding Modal Component
     */
    detectViewport() {
        if (!this.viewport) {
            return;
        }
        
        switch (this.viewport) {
            case 'desktop':
                if (window.innerWidth >= desktopBreakpoint){
                    this.modalInstance.show();
                }
                break;
            case 'mobile' :
                if (window.innerWidth < desktopBreakpoint){
                    this.modalInstance.show();
                }
                break;
            default:
                this.modalInstance.show();
                break;
        }
    }
}

module.exports = OnboardingModal;
