const OnboardingModal = require('../sharable/OnboardingModal');
const OnboardingAlert = require('../sharable/OnboardingAlert');
const NotificationSpotLight = require('../sharable/Spotlight/Implementations/NotificationSpotlight.js');

(async () => {
    const voicesThemeJSVariablesElement = document.getElementById('voicestheme-js-variables');
    const dataObject = voicesThemeJSVariablesElement ? JSON.parse(voicesThemeJSVariablesElement.getAttribute('data-object')) : {};

    const {
          voicestheme_js_variables: {
              client_marketing_notification_tour_onboarding_action: onboardingActionMarketingNotificationTour = ''
          } = {}
      } = dataObject || {};

    const notificationElement = document.getElementById('Notifications');

    if(onboardingActionMarketingNotificationTour && onboardingActionMarketingNotificationTour !== 'completed' && notificationElement){
        new NotificationSpotLight();
    }
})();



const onboardingModals = Array.prototype.slice.call(document.querySelectorAll('.onboarding-modal'));
if (onboardingModals) {
    onboardingModals.forEach(modal => {
        new OnboardingModal(modal);
    });
}


const onboardingAlerts = Array.prototype.slice.call(document.querySelectorAll('.onboarding-alert'));
if (onboardingAlerts) {
    onboardingAlerts.forEach(alert => {
        new OnboardingAlert(alert);
    });
}
