const introJS = require('@node_modules/intro.js/intro');
// Remove any jQuery Browser Definition
delete window.jQuery;
/**
 * Desktop Size Breakpoint
 * @type {Number}
 */
const DESKTOP_BREAKPOINT = 992;

class Spotlight {

    /**
     * @constructor
     * @param {string} onboardingKey            Onboarding Key associated with the Spotlight tour
     * @param {integer} memberID                Member ID for the member who is performing the onboarding event
     * @param {string} buttonClass              Add CSS class to all buttons
     * @param {boolean} scrollToElement         Auto scroll to highlighted element if it’s outside of viewport
     * @param {boolean} hidePrev                Hide previous button in the first step
     * @param {boolean} exitOnOverlayClick      Exit introduction when clicking on overlay layer
     * @param {boolean} exitOnEsc               Exit introduction when pressing Escape button
     * @param {boolean} showBullets             Display Bullets as a step
     * @param {boolean} showStepNumbers         Show steps number in the red circle or not
     * @param {string} doneLabel                Done button label
     * @param {string} skipLabel                Skip button label
     * @param {string} nextLabel                Next button label
     * @param {string} prevLabel                Previous button label
     * @param {string} tooltipClass             Add CSS class to all tooltips
     * @param {boolean} disableInteraction      To disable interactions with elements inside the highlighted box
     * @param {boolean} disableViewportOverflow To disable viewport overflow
     * @param {array} steps                     Spotlight Steps
     */
    constructor({
        onboardingKey = '',
        memberID = null,
        buttonClass = 'btn',
        scrollToElement = false,
        hidePrev = true,
        exitOnOverlayClick = false,
        exitOnEsc = false,
        showBullets = false,
        showStepNumbers = false,
        doneLabel = 'Done',
        skipLabel = '<i class="far fa-times"></i>',
        nextLabel = 'Next',
        prevLabel = 'Back',
        tooltipClass = 'spotlight-modal',
        disableInteraction = true,
        disableViewportOverflow = false,
        steps = []
    } = {}) {
        if (!steps.length === 0) {
            console.error('Error: Spotlight Steps Required to Initialize Component');
            return;
        }

        if (onboardingKey === '') {
            console.error('Error: Spotlight Onboarding Key Required to Initialize Component');
            return;
        }

        if (memberID === null) {
            console.error('Error: Spotlight Member ID Required to Initialize Component');
            return;
        }

        /**
         * Only Enable Spotlight on Desktop
         */
        if (window.innerWidth < DESKTOP_BREAKPOINT) {
            return;
        }

        /**
         * Spotlight Options.
         */
        this.options = {
            buttonClass,
            scrollToElement,
            hidePrev,
            exitOnOverlayClick,
            exitOnEsc,
            showBullets,
            doneLabel,
            showStepNumbers,
            skipLabel,
            nextLabel,
            prevLabel,
            tooltipClass,
            disableInteraction,
            steps
        };

        this.onboardingKey = onboardingKey;
        this.memberID = memberID;
        this.completeOnboardingURL = `/onboarding/ajax_complete/${this.onboardingKey}/${this.memberID}`;
        this.ignoreOnboardingURL = `/onboarding/ajax_ignore/${this.onboardingKey}/${this.memberID}`;
        this.disableViewportOverflow = disableViewportOverflow;
        this.spotlight = this.initializeSpotlightTour(this.options);

        /**
         * Attach Event Listeners for Spotlight to become initialized
         */
        this._attachEventListeners();
    }

    /**
     * Attach Event Listeners
     */
    _attachEventListeners() {
        this.spotlight.onskip(() => this.handleOnboardingEvent(this.ignoreOnboardingURL));
        this.spotlight.oncomplete(() => this.handleOnboardingEvent(this.completeOnboardingURL));
    }

    /**
     * Initialize Spotlight Component
     * @param options {Array}
     */
    initializeSpotlightTour(options) {
        const intro = introJS();

        intro.setOptions(options);

        intro.onbeforeexit(() => {
            document.body.style.overflowY = '';
        });

        intro.start();

        if (!this.disableViewportOverflow) {
            document.body.style.overflowY = 'hidden';
        }

        return intro;
    }

    /**
     * Handle Onboarding ajax request
     * @param requestURL    {string}
     */
    handleOnboardingEvent(requestURL) {
        fetch(requestURL, {
            method: 'POST',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then(response => {
            if (!response.ok) {
                return response.json().then(res => { throw new Error(res.error); });
            }
        }).catch(error => {
            console.log(error.message);
        });
    }
}

module.exports = Spotlight;
