const Spotlight = require('../Core/Spotlight');

class NotificationSpotlight extends Spotlight {

    /**
     * Constructor
     */
    constructor() {
        const voicesThemeJSVariablesElement = document.getElementById('voicestheme-js-variables');
        const dataObject = element ? JSON.parse(voicesThemeJSVariablesElement.getAttribute('data-object')) : {};

        const {
            voicestheme_js_variables: {
                member_id: memberID = null,
            } = {}
        } = dataObject || {};

        if (!memberID) return;

        super({
            buttonClass: 'btn btn-sm',
            memberID,
            onboardingKey: 'client_notification_tour',
            showBullets: false,
            disableInteraction: true,
            doneLabel: 'Got It!',
            steps: [
                {
                    title: 'Launched: Notifications',
                    element: document.getElementById('Notifications'),
                    intro: `Notifications will keep you informed about jobs, responses, and platform updates. Click the bell icon to access your notifications.`,
                    position: 'top-right-aligned'
                }
            ]
        });
    }
}

module.exports = NotificationSpotlight;
